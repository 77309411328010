import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page.jsx";
import Divider from '../components/atoms/Divider';
import Spacer from '../components/atoms/Spacer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`📦v1.1`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Bug fixes`}</li>
    </ul>
    <br />
    <Divider mdxType="Divider" />
    <br />
    <h2>{`📦v1.0`}</h2>
    <p>{`Initial Release`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      